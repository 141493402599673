import reactStringReplace from 'react-string-replace';
import { Emoji } from './Emoji';
import { SERVER_BASE_URL } from './App';



/*function reactStringReplace(pcs, re, fn, limit = 10) {
	if (!(pcs instanceof Array)) pcs = [pcs];
	return pcs.map(str => {
		if (typeof str != "string") return str;
		var split = str.split(re);
		for (var i = 1; i < split.length && ((i + 1) / 2) < limit; i += 2) {
			split[i] = fn(split[i]);
		}
		return split;
	}).flat();
}*/





export function processMessageContent(content) {
	if (!content) return;
	if (content.length > 5000) {
		content = content.substring(0, 5000) + `[${content.length - 5000} chars truncated]`;
	}

	// youtube
	content = reactStringReplace(content, /https?:\/\/(?:(?:www.)?youtube.com|youtu.be)\/(?:watch\?v=)?([a-zA-Z0-9-_]{11})/gi, id => <iframe className="inline-block align-top max-w-[560px] max-h-[315px] w-full h-full aspect-video" src={`https://www.youtube-nocookie.com/embed/${id}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>);

	// hyperlinks
	content = reactStringReplace(content, /(https?:\/\/\S+)/gi, link => <a href={link} target="_blank" rel="noopener" style={{ color: "revert", textDecoration: "revert" }}>{link}</a>
	);

	// emoji
	content = reactStringReplace(content, /:([a-z0-9-_]+):/gi, emoji => <Emoji emoji={emoji} />);

	// files
	content = reactStringReplace(content, /\[img:(\S+)\]/gi, x => {
		var url = SERVER_BASE_URL + "/objects/" + x;
		return <a href={url} target="_blank" rel="noopener">
			<img src={url} alt="" className="max-h-32 inline-block align-top border" />
		</a>;
	});
	content = reactStringReplace(content, /\[video:(\S+)\]/gi, x => {
		var url = SERVER_BASE_URL + "/objects/" + x;
		return <video className='max-h-64 inline-block align-top border' controls>
			<source src={url} />
		</video>;
	});
	content = reactStringReplace(content, /\[audio:(\S+)\]/gi, x => {
		var url = SERVER_BASE_URL + "/objects/" + x;
		return <audio className='max-h-64 inline-block align-top' controls>
			<source src={url} />
		</audio>;
	});
	content = reactStringReplace(content, /\[file:(\S+)\]/gi, x => {
		var url = SERVER_BASE_URL + "/objects/" + x;
		var filename = x.split('/').pop() || x;
		return <a href={url} target="_blank" rel="noopener">{filename}</a>;
	});

	return content;
}
